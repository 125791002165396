import {setSearchQuery} from '@store/slices/search';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {useFormikContext} from 'formik';
import {FC, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  List,
  QueryContainer,
  QueryItem,
  SearchBySitePastReqListWrapper,
} from './SearchBySitePastReqList.styles';

interface SearchBySitePastReqListProps {
  open: boolean;
  queryList: string[];
  query: string;
  top?: number;
}

const SearchBySitePastReqList: FC<SearchBySitePastReqListProps> = ({
  open,
  queryList,
  query,
  top,
}) => {
  const [filteredQueryList, setFilteredQueryList] = useState<string[]>([]);

  const formik = useFormikContext();

  const dispatch = useDispatch();

  useEffect(() => {
    const queryArr = queryList.filter(
      queryItem => queryItem.includes(query) && queryItem !== query,
    );
    setFilteredQueryList(queryArr);
  }, [query]);

  return (
    <SearchBySitePastReqListWrapper
      open={open && filteredQueryList.length !== 0}
      top={top}>
      <List>
        {filteredQueryList.length !== 0 &&
          filteredQueryList.slice(0, 5).map((queryItem, i) => (
            <QueryContainer key={i}>
              <GlobalContainer>
                <QueryItem
                  onClick={() => {
                    if (formik) {
                      formik.setFieldValue('query', queryItem);
                    }
                    dispatch(setSearchQuery(queryItem));
                  }}>
                  {queryItem}
                </QueryItem>
              </GlobalContainer>
            </QueryContainer>
          ))}
      </List>
    </SearchBySitePastReqListWrapper>
  );
};

export default SearchBySitePastReqList;

import {SearchProductEntity} from '@api/types/SearchBySiteResponse';
import useLocalStorage from '@hooks/useLocalStorage';
import {searchQuery, setSearchQuery} from '@store/slices/search';
import {getAllSearchEntities, getSearchQueryObject} from '@utils/search';
import {Formik, FormikValues} from 'formik';
import Image from 'next/legacy/image';
import {useRouter} from 'next/router';
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SearchBySiteDropdownMenu from './SearchBySiteDropdownMenu';
import {
  Buttons,
  ImageWrapper,
  SearchButton,
  SearchForm,
  SearchFormWrapper,
  SearchInput,
} from './SearchBySiteForm.styles';

interface SearchBySiteFormProps {
  entities: SearchProductEntity[];
  width?: number;
  openSearchForm?: boolean;
  testHandleSubmit?: (values: FormikValues) => void;
  testID?: string;
  setShowPastReqList: Dispatch<SetStateAction<boolean>>;
  searchInputRef: any;
}

export interface ISearchBySiteInitialState {
  query: string;
}

const SearchBySiteForm: FC<SearchBySiteFormProps> = ({
  entities,
  openSearchForm,
  searchInputRef,
  testHandleSubmit,
  setShowPastReqList,
  testID,
}) => {
  const router = useRouter();

  const [query, setQuery] = useLocalStorage('query', []);

  const formikRef: any = useRef(null);

  const inputRef: any = useRef(null);
  const initialEntites = getAllSearchEntities(entities);

  const dispatch = useDispatch();

  const searchQ = useSelector(searchQuery);

  const [queryEntities, setQueryEntites] = useState({});

  useEffect(() => {
    searchInputRef.current = inputRef.current;
    if (
      router.query?.query &&
      String(router.query.query).length !== 0 &&
      searchQ.length === 0 &&
      formikRef.current.values.query.length !== 0
    ) {
      dispatch(setSearchQuery(String(router.query.query)));
    }
    formikRef.current.setFieldValue(
      'query',
      searchQ.length === 0
        ? router.query.query
          ? String(router.query.query)
          : ''
        : searchQ,
    );
    inputRef.current.value = searchQ;
    formikRef.current.setFieldValue('query', searchQ);
  }, [searchQ]);

  useEffect(() => {
    const entites = (router.query?.entity as unknown as Record<string, string>) ?? {};
    const mappedEntites = Object.entries(entites).reduce(
      (obj, entity) => ({
        ...obj,
        [`entity[${entity[0]}]`]: entity[1] === 'true',
      }),
      {},
    );

    setQueryEntites({
      ...mappedEntites,
      query: router.query?.query ?? '',
    });
  }, [router, entities]);

  const onSubmit = (values: FormikValues) => {
    if (values.query.length === 0) {
      return;
    }
    if (query.indexOf(values.query) === -1) {
      setQuery([...query, values.query]);
    }

    router.replace(
      {
        pathname: '/search',
        query: {...getSearchQueryObject(values), page: 1},
      },
      undefined,
      {scroll: false},
    );
  };

  return (
    <SearchFormWrapper data-testid={testID}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          ...initialEntites,
          query: searchQ,
          ...queryEntities,
        }}
        onSubmit={(values: FormikValues) => {
          if (testHandleSubmit) {
            testHandleSubmit(values);
            return;
          }
          onSubmit(values);
        }}>
        {({handleSubmit, values, setFieldValue}) => (
          <>
            <SearchForm onSubmit={handleSubmit}>
              <SearchInput>
                <ImageWrapper>
                  <Image
                    src="/assets/searchIconInput.svg"
                    layout="intrinsic"
                    width={17.5}
                    height={17.5}
                    objectFit="scale-down"
                    alt="Иконка поиска"
                  />
                </ImageWrapper>
                <input
                  ref={inputRef}
                  value={values.query}
                  id="query"
                  name="query"
                  type="string"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('query', e.target.value);
                    dispatch(setSearchQuery(e.target.value));
                  }}
                  onFocus={() => setShowPastReqList(true)}
                  onBlur={() => setShowPastReqList(false)}
                  placeholder="Поиск"
                />
              </SearchInput>

              <Buttons>
                <SearchButton type="submit">Найти</SearchButton>
              </Buttons>
              <SearchBySiteDropdownMenu entities={entities} />
            </SearchForm>
          </>
        )}
      </Formik>
    </SearchFormWrapper>
  );
};

export default SearchBySiteForm;

import {theme} from '@styles/theme';
import styled from 'styled-components';

export const SearchBySiteDropdownMenuWrapper = styled.div`
  position: relative;
  border-right: 1px solid rgba(0, 117, 90, 0.2);
  border-left: 1px solid rgba(0, 117, 90, 0.2);
  display: flex;
  flex: 1 1 40%;
  button {
    padding: 8px 30px 8px 10px;
    cursor: pointer;
  }
`;

export const DropdownMenu = styled.div`
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.1px;
  position: absolute;
  top: 100%;
  left: -1%;
  width: 97%;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  ${theme.flexAlignItemsCenter};
  flex-wrap: wrap;
  height: 209px;
  overflow: auto;
  padding: 15px 0 15px 15px;
  margin-top: 15px;
  z-index: ${({isOpen}: {isOpen: boolean}) => (isOpen ? '999' : '-1')};
  opacity: ${({isOpen}: {isOpen: boolean}) => (isOpen ? '1' : '0')};
  transition: all 0.25s;
  &::-webkit-scrollbar {
    height: 6px;
    width: 5px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    width: 3px;
    background: #dbdbdb;
    border-radius: 5px;
    &:hover {
      background-color: ${theme.colors.brandGreenV2};
    }
  }
`;

export const DropdownTitle = styled.button`
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #1f1f1f;
  display: inline-flex;
  gap: 5px;
  width: 100%;
  min-height: 20px;
  position: relative;
  &:last-child {
    margin-left: 200px;
  }
  &:after {
    content: url('/assets/icons/arrowDown.svg');
    margin-left: auto;
    position: absolute;
    right: 10px;
    transform: ${({isOpen}: {isOpen: boolean}) => (isOpen ? 'rotateX(180deg)' : 'none')};
    transition: transform 0.25s;
  }
`;

export const DropdownItem = styled.div`
  display: block;
  white-space: nowrap;
`;

export const SearchEntity = styled.div`
  &:not(first-child) {
    padding: 5px 0;
  }
  ${theme.flexAlignItemsCenter};
  flex: 1 1 100%;
  justify-content: start;
  label {
    font-size: 13px;
  }
`;

import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import {Breadcrumb} from '@api/types/Breadcrumbs';
import {sendClientParamsNATS} from '@utils/common';
import Link from 'next/link';
import {FC} from 'react';
import {
  DesktopHeaderCategoryMenuWrapper,
  MenuLinks,
} from './DesktopHeaderCategoryMenu.styles';

interface DesktopHeaderCategoryMenuProps {
  isOpen: boolean;
  menuItems: Array<Breadcrumb>;
}

const DesktopHeaderCategoryMenu: FC<DesktopHeaderCategoryMenuProps> = ({
  isOpen,
  menuItems,
}) => {
  return (
    <>
      {menuItems && menuItems.length !== 0 && (
        <DesktopHeaderCategoryMenuWrapper isOpen={isOpen}>
          <MenuLinks>
            {menuItems.map((aboutCompanyItem, i) => (
              <li
                key={i}
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ItemList">
                <Link
                  href={aboutCompanyItem.path}
                  itemProp="url"
                  style={{display: 'block'}}
                  onClick={() => {
                    sendClientParamsNATS(AnalyticEventNames.PageView, {
                      eventName: AnalyticEventNames.MainPageClickCategory,
                      eventCategory: AnalyticEventCategories.MainPage,
                      eventParams: {
                        category: aboutCompanyItem.title as string,
                      },
                    });
                  }}>
                  {aboutCompanyItem.title}
                  <meta itemProp="name" content={aboutCompanyItem.title} />
                </Link>
              </li>
            ))}
          </MenuLinks>
        </DesktopHeaderCategoryMenuWrapper>
      )}
    </>
  );
};

export default DesktopHeaderCategoryMenu;

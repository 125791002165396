import {SearchProductEntity} from '@api/types/SearchBySiteResponse';
import Checkbox from '@components/atoms/Checkbox';
import {theme} from '@styles/theme';
import {setCheckedNamesArr, setSearchByAllFields} from '@utils/search';
import {FormikValues, useFormikContext} from 'formik';
import {useRouter} from 'next/router';
import {FC, useEffect, useState} from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownTitle,
  SearchBySiteDropdownMenuWrapper,
  SearchEntity,
} from './SearchBySiteDropdownMenu.styles';

interface SearchBySiteDropdownMenuProps {
  entities: SearchProductEntity[];
}

const SearchBySiteDropdownMenu: FC<SearchBySiteDropdownMenuProps> = ({entities}) => {
  const formik = useFormikContext<FormikValues | any>();
  const [isOpen, setIsOpen] = useState(false);
  const [searchByAll, setSearchByAll] = useState<boolean>(true);
  const [checked, setChecked] = useState<string[]>([]);

  const router = useRouter();

  useEffect(() => {
    window.addEventListener('click', () => setIsOpen(false));
    return () => window.removeEventListener('click', () => setIsOpen(false));
  }, []);

  useEffect(() => {
    if (entities && entities.length !== 0 && checked.length === entities.length) {
      setSearchByAll(true);
    }
  }, [checked]);

  useEffect(() => {
    const checkedArr: any[] = [];
    const queryEntities =
      (router.query.entity as unknown as Record<string, string>) ?? [];

    const entitesValues = entities?.length
      ? entities?.reduce((obj, entity) => {
          if (Object.keys(queryEntities).includes(entity.code)) {
            checkedArr.push(entity.title);
            return {
              ...obj,
              [`entity[${entity.code}]`]: true,
            };
          }
          return obj;
        }, {})
      : {};

    formik.setValues({
      ...formik.values,
      ...entitesValues,
    });
    if (checkedArr.length !== 0) {
      setChecked([...checkedArr]);
    }
  }, [router, entities]);

  useEffect(() => {
    setCheckedNamesArr(
      formik.values,
      formik.setValues,
      setChecked,
      setSearchByAll,
      checked,
      entities,
    );
  }, [formik.values]);

  return (
    <SearchBySiteDropdownMenuWrapper>
      <DropdownTitle
        onClick={e => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        type="button"
        isOpen={isOpen}>
        {!searchByAll &&
          checked
            .slice(0, 3)
            .map((item, i) => (
              <DropdownItem key={i}>{`${i ? '| ' : ''}${item}`}</DropdownItem>
            ))}
        {searchByAll && <DropdownItem>По всему сайту</DropdownItem>}
        {checked.length > 3 && <DropdownItem>...</DropdownItem>}
      </DropdownTitle>
      <DropdownMenu isOpen={isOpen} onClick={e => e.stopPropagation()}>
        <SearchEntity>
          <Checkbox
            checked={Boolean(searchByAll)}
            id="all"
            onChange={() => setSearchByAllFields(formik.values, formik.setValues)}
            label="По всему сайту"
            labelColor={`${theme.colors.darkText}`}
            labelFontSize="13px"
            variant="filled"
            checkboxSquareHeight={17}
            checkboxSquareWidth={17}
            checkMarkPosRight={12}
            checkMarkPosTop={15}
            marginBetweenLabel={12}
          />
        </SearchEntity>
        {entities &&
          entities.map((entity, i) => (
            <SearchEntity key={i}>
              <Checkbox
                checked={Boolean(formik.values[`entity[${entity.code}]`])}
                name={`entity[${entity.code}]`}
                id={`entity[${entity.code}]`}
                variant="filled"
                labelColor={`${theme.colors.darkText}`}
                labelFontSize="13px"
                checkboxSquareHeight={17}
                checkboxSquareWidth={17}
                checkMarkPosRight={12}
                checkMarkPosTop={15}
                marginBetweenLabel={12}
                onChange={e => {
                  formik.setValues({
                    ...formik.values,
                    [`entity[${entity.code}]`]: e.target.checked,
                  });
                  if (e.target.checked) {
                    setChecked([...checked, entity.title]);
                  }
                  if (!e.target.checked && checked.includes(entity.title)) {
                    setChecked([...checked.filter(item => item !== entity.title)]);
                  }
                }}
                label={entity.title}
              />
            </SearchEntity>
          ))}
      </DropdownMenu>
    </SearchBySiteDropdownMenuWrapper>
  );
};

export default SearchBySiteDropdownMenu;

import styled from 'styled-components';

export const SearchBySitePastReqListWrapper = styled.div`
  opacity: ${({open}: {open: boolean}) => (open ? '1' : '0')};
  z-index: ${({open}: {open: boolean}) => (open ? '99' : '-1')};
  position: absolute;
  top: ${({top}: {top?: number | undefined; open: boolean}) =>
    top ? `${top}%` : '100%'};
  left: 0;
  background-color: white;
  width: 100%;
  transition: all 0.25s;
  border-bottom: 1px solid #e6e6e6;
`;

export const List = styled.div``;

export const QueryContainer = styled.div`
  padding-left: 100px;
  &:hover {
    background-color: rgba(217, 217, 217, 0.5);
    cursor: pointer;
  }
  @media (max-width: 1250px) {
    padding-left: 80px;
  }
  @media (max-width: 1200px) {
    padding-left: 70px;
  }
  @media (max-width: 1170px) {
    padding-left: 60px;
  }
  @media (max-width: 1100px) {
    padding-left: 50px;
  }
`;

export const QueryItem = styled.div`
  padding: 5px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.1px;
`;

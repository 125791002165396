import {theme} from '@styles/theme';
import styled from 'styled-components';

export const DesktopHeaderCategories = styled.div`
  border-top: 1px solid ${theme.colors.lightGrayV2};
  border-bottom: 1px solid ${theme.colors.lightGrayV2};
  position: relative;
  background-color: ${({showSearch}: {showSearch: boolean}) =>
    showSearch ? 'white' : 'transparent'};
  position: relative;
  z-index: 1;
  padding-right: 1px;
`;

export const DesktopHeaderCategoriesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DesktopHeaderCategoryWrapper = styled.li`
  position: relative;
`;

export const DesktopHeaderCategory = styled.div<{active: boolean}>`
  width: fit-content;
  font-size: 14px;
  padding: 16px 0;
  transition: color 0.3s;
  cursor: pointer;
  white-space: nowrap;
  color: ${({active}: {active?: boolean}) =>
    active ? `${theme.colors.brandGreenV2} !important` : `${theme.colors.darkText}`};
  &:hover {
    color: ${({theme}) => theme.colors.brandGreenV2};
    transition: color 0.3s;
  }
`;

export const DropdownHeaderCategory = styled(DesktopHeaderCategory)`
  &:first-child {
    padding-top: 15px;
  }
  &:last-child {
    padding-bottom: 15px;
  }
  padding-top: 10px;
  padding-bottom: 0px;
  a {
    color: ${theme.colors.darkText};
    &:hover {
      color: ${theme.colors.brandGreenV2};
    }
  }
`;

export const DesktopHeaderSearch = styled.div<{showSearchForm: boolean}>`
  min-width: 16px;
  height: 16px;
  padding-top: 3px;
  cursor: pointer;
  display: ${({showSearchForm}) => (showSearchForm ? 'none' : '')};
`;

export const DesktopHeaderCategoriesContainer = styled.menu<{
  showSearch: boolean;
  searchPage: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  opacity: ${({showSearch, searchPage}) => (showSearch && !searchPage ? '0' : '1')};
  a {
    color: ${theme.colors.darkText};
  }
`;

export const SearchFormContainer = styled.div<{searchPage: boolean; showSearch: boolean}>`
  position: absolute;
  top: ${({searchPage}) => (searchPage ? '100%' : '0%')};
  width: 100%;
  left: 0%;
  opacity: ${({showSearch}) => (showSearch ? '1' : '0')};
  z-index: ${({showSearch}) => (showSearch ? '9999' : '-1')};
  transition: all 0.15s;
`;

export const Form = styled.div`
  position: relative;
  z-index: 10;
  width: 1130px;
  @media (max-width: 1180px) {
    width: 98%;
  }
`;

export const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 999;
  top: 30%;
  right: -20px;
  width: 24px;
  height: 24px;

  &:before {
    content: '';
    position: absolute;
    top: 15%;
    left: 43%;
    height: 15px;
    width: 1.5px;
    background-color: black;
    transform: rotate(-45deg);
  }

  &:after {
    content: '';
    top: 15%;
    left: 43.01%;
    position: absolute;
    transform: rotate(45deg);
    height: 15px;
    width: 1.5px;
    background-color: black;
  }
`;

export const HamburgerButton = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  height: 20px;
  div {
    background-color: ${({open}: {open: boolean}) =>
      open ? theme.colors.brandGreen : `${theme.colors.darkText}`};
    z-index: ${({open}: {open: boolean}) => (open ? 99999 : 1)};
  }
`;

export const HamburgerDot = styled.div`
  height: 3.5px;
  width: 3.5px;
  border-radius: 50%;
`;

export const BurgerWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const BurgerMenu = styled.div<{isOpen: boolean}>`
  position: absolute;
  background-color: white;
  border: 1px solid ${theme.colors.lightGrayV2};
  top: 150%;
  right: -50%;
  padding: 0 15px;
  min-width: 168px;
  z-index: ${({isOpen}) => (isOpen ? '99999' : '-1')};
  opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
  transition: all 0.25s;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  div {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: ${theme.colors.darkText};
  }
`;

export const BurgerAndSearchWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 31.5px;
`;

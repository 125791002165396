import apiClient from '@api/entities/apiClient';

export const searchBySite = async (searchObj: Record<string, any>) => {
  const {data} = await apiClient.get(`/Search`, {
    params: {
      ...searchObj,
    },
  });
  return data;
};

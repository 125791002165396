import {theme} from '@styles/theme';
import styled from 'styled-components';

export const SearchFormWrapper = styled.div`
  display: flex;
  background-color: transparent;
  width: 100%;
  position: relative;
  z-index: 10;
`;

export const SearchForm = styled.form`
  position: relative;
  width: 100%;
  padding: 10.5px 10px 10.5px 0px;
  margin: 0 auto;
  ${theme.flexAlignItemsCenter};
  justify-content: space-between;
`;
export const SearchInput = styled.div`
  ${theme.flexAlignItemsCenter};
  width: 100%;
  gap: 25px;
  margin-right: 20px;
  input {
    font-size: 16px;
    border: none;
    outline: none;
    width: 100%;
    border: 1px solid transparent;
    border-radius: ${theme.rounded};
    transition: border 0.5s;
    background-color: transparent;
  }
`;

export const ImageWrapper = styled.div`
  margin-top: 2px;
`;

export const Buttons = styled.div`
  display: flex;
  flex: 1 1 10.5%;
  flex-wrap: wrap;
  gap: 10px;
  margin-right: 20px;
  button {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    margin: 0 auto;
    cursor: pointer;
    width: 100%;
    padding: 8px 15px;
    border: none;
    background-color: white;
    color: ${theme.colors.brandGreenV2};
    border-radius: ${theme.rounded};
    transition: background 0.4s, color 0.4s;
    &:hover {
      border: none;
      text-decoration: underline;
      color: ${theme.colors.brandGreenV2};
    }
  }
`;

export const SearchButton = styled.button`
  background-color: ${({theme}) => theme.colors.brandGreen};
  color: ${theme.colors.white};
  padding: 0 20px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${theme.colors.brandGreenV2};
    background-color: transparent;
    color: black;
  }
`;

import {theme} from '@styles/theme';
import styled from 'styled-components';

export const DesktopHeaderCategoryMenuWrapper = styled.div`
  opacity: ${({isOpen}: {isOpen: boolean}) => (isOpen ? '1' : '0')};
  position: absolute;

  background-color: ${theme.colors.white};
  z-index: ${({isOpen}: {isOpen: boolean}) => (isOpen ? '99999' : '-1')};
  top: 85%;
  left: -10%;
  transition: all 0.25s;
  pointer-events:  ${({isOpen}: {isOpen: boolean}) => (isOpen ? 'auto' : 'none')};

  &:hover {
    opacity: 1;
    z-index: 99999;
  }
`;

export const MenuLinks = styled.menu`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(7, auto);

  max-height: 175px;
  width: max-content;

  border: 1px solid ${theme.colors.lightGrayV2};
  border-radius: 4px;

  padding: 10px 5px;
  margin-bottom: -3px;
  background-color: white;

  @media (max-width: 1000px) {
    grid-template-rows: repeat(10, auto);
    max-height: 400px;
  }

  a {
    color: ${theme.colors.darkText} !important;
    font-size: 13px;
    padding: 5px 10px;
    max-height: 15px; // Firefox workaround
    transition: color 0.5s;
    &:hover {
      color: ${({theme}) => theme.colors.brandGreenV2} !important;
      transition: color 0.5s;
    }
  }
`;

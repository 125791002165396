import Button from '@components/atoms/Button';
import CabHeaderUserMenu from '@components/molecules/CabHeaderUserMenu';
import DesktopHeaderCategoriesPanel from '@components/molecules/DesktopHeader/DesktopHeaderCategoriesPanel';
import {ENABLE_NEW_CABINET} from '@config/cabinet';
import {PENDING} from '@constants/loadingState';
import {RootState} from '@store';
import {setConfirmModalActive} from '@store/slices/geolocation';
import {setSearchQuery} from '@store/slices/search';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {getIsUseNewLKK} from '@utils/LKK_AB_test';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  Calls,
  ContentWrapper,
  CurrentTown,
  CurrentTownImage,
  CurrentTownText,
  DesktopHeaderButton,
  DesktopHeaderButtons,
  DesktopHeaderServices,
  DesktopHeaderWrapper,
  LogoWrapper,
  MainWrapper,
  Number,
  Numbers,
  NumberSeparator,
  Personal,
  PersonalImage,
  PersonalText,
  Quantity,
  CallbackButton
} from './DesktopHeader.styles';
import Skeleton from 'react-loading-skeleton';

interface DesktopHeaderProps {
  isAuth: boolean;
  isRegistration: boolean;
}

const DesktopHeader: FC<DesktopHeaderProps> = ({isAuth, isRegistration}) => {
  // TODO: Реализовать прослушиватель роутов по пути и с помощью него устанавливать pageTitle

  const dispatch = useDispatch();

  const router = useRouter();

  const cartShoppingItems = useSelector((state: RootState) => state.catalog.cartItems);
  const favItems = useSelector((state: RootState) => state.catalog.favoriteItems);
  const favSaleItems = useSelector((state: RootState) => state.catalog.favoriteSaleItems);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

  const favSum = favItems.length + favSaleItems.length;
  const {account, accountLoading} = useSelector((state: RootState) => state.user);
  const {geo} = useSelector((state: RootState) => state.geolocation);

  const isUseNewLKK = getIsUseNewLKK(account?.roles);

  const onCabinetClick = () => {
    if (accountLoading === PENDING) return;
    if (isAuth) {
      if (isUseNewLKK) {
        setUserMenuIsOpen(prev => !prev);
      } else {
        router.push('/cabinet');
      }
    } else {
      router.push('/cabinet/v2/auth');
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', () => dispatch(setSearchQuery('')));
    return () => {
      router.events.off('routeChangeComplete', () => dispatch(setSearchQuery('')));
    };
  }, []);


  const userFullName = account?.last_name ? `${account?.last_name} ${account?.name}` : '';


  // Если скролл-лок будет больше не нужен - удалить закомментированные ниже строчки, а также MenuBackdrop

  // useLayoutEffect(() => {
  //   fixedBody();
  // }, [isOpen]);

  // const fixedBody = () => {
  //   if (document.body.style.position === '' && isOpen) {
  //     document.body.style.position = 'fixed';
  //   } else {
  //     document.body.style.position = '';
  //   }
  // };

  console.log('isAuth', isAuth); // Почему-то фиксит Personal

  return (
    <MainWrapper>
      <GlobalContainer>
        <ContentWrapper>
          <LogoWrapper>
            <Link href={'/'} passHref>
              <Image
                src={'/assets/icons/logo.svg'}
                layout="fill"
                alt="Логотип компании АО «Росагролизинг»"
                title="АО «Росагролизинг»"
                objectFit="contain"
              />
            </Link>
          </LogoWrapper>
          <DesktopHeaderWrapper>
            <CurrentTown
              onClick={e => {
                e.stopPropagation();
                dispatch(setConfirmModalActive(true));
              }}>
              <CurrentTownImage>
                <Image
                  src={'/assets/icons/gpsTown.svg'}
                  layout="intrinsic"
                  width={12}
                  height={12}
                  alt={`Выбор города`}
                />
              </CurrentTownImage>
              <CurrentTownText>{geo?.city ?? 'Выбрать город'}</CurrentTownText>
            </CurrentTown>
            <Calls>
              <Numbers>
                <Number href="tel:+78002005395">+7 800 200-53-95</Number>
                <NumberSeparator></NumberSeparator>
                <Number href="tel:+74952801333">+7 495 280-13-33</Number>
              </Numbers>
              <CallbackButton
                title="Заказать звонок"
                fontSize={14}
                variant="outlined"
                link={'/client/services/callback'}
              />
            </Calls>
            <DesktopHeaderServices>
              {!ENABLE_NEW_CABINET && (
                <Link
                  href={'https://www.rosagroleasing.ru/cabinet/v2/client/dashboard/'}
                  passHref
                  target="_blank"
                  legacyBehavior>
                  <a>
                    <Personal title={account?.company_name_norm || account?.company_name || userFullName || 'Личный кабинет'}>
                      <PersonalImage>
                        <Image
                          src={'/assets/icons/personal.svg'}
                          layout="intrinsic"
                          width={12}
                          height={12}
                          objectFit="contain"
                          alt="Личный кабинет"
                          title={account?.company_name_norm || account?.company_name || userFullName || 'Личный кабинет'}
                        />
                      </PersonalImage>
                      <PersonalText>
                        {accountLoading === PENDING ? (
                          <Skeleton count={1} width={100} />
                        ) : (
                          account?.company_name
                        )}
                      </PersonalText>
                    </Personal>
                  </a>
                </Link>
              )}
              {ENABLE_NEW_CABINET && !isRegistration && (
                <Personal
                  title={account?.company_name_norm || account?.company_name || userFullName || 'Личный кабинет'}
                  onClick={onCabinetClick}
                  onMouseLeave={() => setUserMenuIsOpen(false)}>
                  <PersonalImage>
                    <Image
                      src={'/assets/icons/personal.svg'}
                      layout="intrinsic"
                      width={12}
                      height={12}
                      objectFit="contain"
                      alt="Личный кабинет"
                      title={account?.company_name_norm || account?.company_name || userFullName || 'Личный кабинет'}
                    />
                  </PersonalImage>
                  <PersonalText>
                    {accountLoading === PENDING ? (
                      <Skeleton count={1} width={104} />
                    ) : (
                    account?.company_name_norm || account?.company_name || userFullName || 'Личный кабинет'
                    )}
                  </PersonalText>
                  <CabHeaderUserMenu
                    isOpen={userMenuIsOpen}
                    setIsOpen={setUserMenuIsOpen}
                  />
                </Personal>
              )}
              {!isRegistration && (
                <DesktopHeaderButtons>
                  <DesktopHeaderButton href={'/compare'} passHref>
                    <Image
                      src={'/assets/icons/barChart.svg'}
                      layout="intrinsic"
                      width={16}
                      height={16}
                      alt="Сравнить товары"
                      title="Сравнить товары"
                    />
                  </DesktopHeaderButton>
                  <DesktopHeaderButton href={'/favorite'} passHref>
                    <Image
                      src={'/assets/icons/favoriteIcon.svg'}
                      layout="intrinsic"
                      width={16}
                      height={16}
                      alt="Избранные товары"
                      title="Избранные товары"
                    />
                    {Boolean(favSum) && (
                      <Quantity style={{right: '-6px'}}>{favSum}</Quantity>
                    )}
                  </DesktopHeaderButton>
                  <DesktopHeaderButton href={'/shoppingCart'} passHref>
                    <Image
                      src={'/assets/icons/shoppingCartIcon.svg'}
                      layout="intrinsic"
                      width={16}
                      height={16}
                      alt="Корзина"
                      title="Корзина"
                    />
                    {Boolean(cartShoppingItems.length) && (
                      <Quantity>{cartShoppingItems.length}</Quantity>
                    )}
                  </DesktopHeaderButton>
                </DesktopHeaderButtons>
              )}
            </DesktopHeaderServices>
          </DesktopHeaderWrapper>
        </ContentWrapper>
      </GlobalContainer>
      {!isRegistration && <DesktopHeaderCategoriesPanel />}
    </MainWrapper>
  );
};

export default DesktopHeader;

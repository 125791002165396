import {SearchProductEntity} from '@api/types/SearchBySiteResponse';
import {FormikValues} from 'formik';

const getAllSearchEntities = (searchEntities: SearchProductEntity[]) => {
  if (!searchEntities) return;
  const allEntityArr = Object.values(searchEntities)
    .filter(item => item.code !== undefined)
    .map((item: SearchProductEntity) => {
      return item.code;
    });

  const obj: Record<string, any> = {};
  for (const key of allEntityArr) {
    obj[`entity[${key}]`] = false;
  }
  return obj;
};
const getSearchQueryObject = (values: Record<string, any>) => {
  const obj = Object.fromEntries(
    Object.entries(values).filter(
      ([key, val]) => val === true || typeof val === 'string',
    ),
  );
  return obj;
};

const setSearchByAllFields = (
  formikValues: FormikValues,
  formikSetValues: (values: any, shouldValidate?: boolean | undefined) => void,
) => {
  const obj: any = {};
  Object.keys(formikValues).forEach(value => {
    if (value.startsWith('entity[')) {
      obj[value] = false;
    }
  });
  formikSetValues({...formikValues, ...obj});
};

const setCheckedNamesArr = (
  formikValues: FormikValues,
  formikSetValues: any,
  setChecked: (str: string[]) => void,
  setSearchByAll: (check: boolean) => void,
  checked: string[],
  entities: SearchProductEntity[],
) => {
  let count = 0;
  const checkedArr: string[] = [];

  for (const [key, value] of Object.entries(formikValues)) {
    if (key.startsWith('entity') && (typeof value === 'boolean' && value === true) || value === 'true') {
      checkedArr.push(key);
      count++;
    }
  }

  entities?.map(entity => {
    checkedArr.map(checked => {
      if (String(entity) === checked) {
        setChecked([entity.title, ...checked]);
      }
    });
  });

  if (!entities) {
    setSearchByAll(true);
    return;
  }

  if (count === 0) {
    setSearchByAll(true);
    setChecked(['По всему сайту']);
    return;
  }

  if (count === entities?.length) {
    setSearchByAll(true);
    setSearchByAllFields(formikValues, formikSetValues);
    setChecked(['По всему сайту']);
    return;
  }

  if (count > 0 && checked.length !== 0) {
    const arr = checked.filter(_ => !_.includes('По всему сайту'));
    setSearchByAll(false);
    setChecked(arr);
    return;
  }
};

const generateLinkToItem = (entity: string, itemID: string, url = '/') => {
  switch (entity) {
    case 'SherpProduct':
      return `/sale/${itemID}`;
    case 'CRMProduct':
      return `/catalog/item/${itemID}`;
    case 'CRMSupplier':
      return `/providers/${itemID}`;
    case 'News':
      return `/company/smi/news/${itemID}`;
    case 'Video':
      return `/company/smi/video/${itemID}`;
    case 'Gallery':
      return `/company/smi/photo/${itemID}`;
    case 'Publication':
      return `/company/smi/publications/${itemID}`;
    case 'Document':
      return '/client/docs/';
    case 'FAQ':
      return '/client/faq';
    case 'Page':
      return url;
    default:
      return '/';
  }
};

export {
  getAllSearchEntities,
  getSearchQueryObject,
  setSearchByAllFields,
  setCheckedNamesArr,
  generateLinkToItem,
};

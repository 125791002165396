import {searchBySite} from '@api/search';
import {Breadcrumb} from '@api/types/Breadcrumbs';
import {useWindowDimensions, useHeaderBreadcrumbsAnalytic} from '@hooks';
import useLocalStorage from '@hooks/useLocalStorage';
import {RootState} from '@store';
import {searchQuery} from '@store/slices/search';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {moveCategoryToBurger} from '@utils/mobile';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import SearchBySiteForm from '../SearchBySite/SearchBySiteForm';
import SearchBySitePastReqList from '../SearchBySite/SearchBySitePastReqList';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import {sendClientParamsNATS} from '@utils/common';
import {
  BurgerAndSearchWrapper,
  BurgerMenu,
  BurgerWrapper,
  CloseBtn,
  DesktopHeaderCategories,
  DesktopHeaderCategoriesContainer,
  DesktopHeaderCategoriesWrapper,
  DesktopHeaderCategory,
  DesktopHeaderCategoryWrapper,
  DesktopHeaderSearch,
  DropdownHeaderCategory,
  Form,
  HamburgerButton,
  HamburgerDot,
  SearchFormContainer,
} from './DesktopHeaderCategoriesPanel.styles';
import DesktopHeaderCategoryMenu from './DesktopHeaderCategoryMenu';

const DesktopHeaderCategoriesPanel = () => {
  const [currentCategoryMenu, setCurrentCategoryMenuOpen] = useState<string>('');
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [burgerIsOpen, setBurgerIsOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const [showPastReqList, setShowPastReqList] = useState(false);
  const [countOfMenuItems, setCountOfMenuItems] = useState({
    main: 8,
    burger: {from: 0, to: 0},
  });

  const searchInputRef: any = useRef(null);

  const router = useRouter();

  const {width} = useWindowDimensions();

  const [searchQList] = useLocalStorage('query', []);

  const searchQ = useSelector(searchQuery);

  const headerCategoryMenuItems = useSelector(
    (state: RootState) => state.catalog.headerCategoryMenuItems,
  );

  useEffect(() => {
    (async () => {
      if (entities && entities.length !== 0) return;
      const {entities: resEntities} = await searchBySite({});
      setEntities(resEntities);
    })();
  }, []);

  useEffect(() => {
    setCountOfMenuItems(moveCategoryToBurger(width || 600));
  }, [width]);

  useEffect(() => {
    if (router.route.includes('/search')) {
      setShowSearchForm(true);
    } else {
      setShowSearchForm(false);
    }
  }, [router]);

  useEffect(() => {
    document.body.addEventListener('click', () => setBurgerIsOpen(false));
    return () => document.body.removeEventListener('click', () => setBurgerIsOpen(false));
  }, []);

  const mainMenu = headerCategoryMenuItems.slice(0, countOfMenuItems.main);
  const burgerMenu = headerCategoryMenuItems.slice(
    countOfMenuItems.burger.from,
    countOfMenuItems.burger.to,
  );

  useHeaderBreadcrumbsAnalytic(mainMenu);

  return (
    <DesktopHeaderCategories showSearch={showSearchForm}>
      <GlobalContainer>
        <DesktopHeaderCategoriesWrapper
          itemScope
          itemType="http://schema.org/SiteNavigationElement">
          <DesktopHeaderCategoriesContainer
            showSearch={showSearchForm}
            searchPage={router.pathname === '/search'}
            itemProp="about"
            itemScope
            itemType="http://schema.org/ItemList">
            {mainMenu.map((category: Breadcrumb) => {
              return (
                <DesktopHeaderCategoryWrapper
                  key={category.path}
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ItemList"
                  onMouseEnter={() => setCurrentCategoryMenuOpen(category.path)}
                  onMouseLeave={() => setCurrentCategoryMenuOpen('')}>
                  <Link href={category.path} passHref itemProp="url">
                    <DesktopHeaderCategory
                      active={router.route.startsWith(category.path.slice(0, 5))}>
                      {category.title}
                      <meta itemProp="name" content={category.title} />
                    </DesktopHeaderCategory>
                  </Link>
                  <div>
                    <DesktopHeaderCategoryMenu
                      isOpen={currentCategoryMenu === category.path}
                      menuItems={
                        headerCategoryMenuItems.find(
                          (item: Breadcrumb) => item.path === category.path,
                        )?.children || []
                      }
                    />
                  </div>
                </DesktopHeaderCategoryWrapper>
              );
            })}
          </DesktopHeaderCategoriesContainer>
          <BurgerAndSearchWrapper>
            {burgerMenu.length > 0 && !showSearchForm && (
              <BurgerWrapper>
                <HamburgerButton
                  open={burgerIsOpen}
                  onClick={e => {
                    e.stopPropagation();
                    setBurgerIsOpen(!burgerIsOpen);
                  }}>
                  <HamburgerDot />
                  <HamburgerDot />
                  <HamburgerDot />
                </HamburgerButton>
                <BurgerMenu isOpen={burgerIsOpen}>
                  {burgerMenu.map((category: Breadcrumb) => (
                    <DropdownHeaderCategory
                      key={category.path}
                      active={router.route.startsWith(category.path.slice(0, 5))}>
                      <Link
                        href={category.path}
                        onClick={() => {
                          setBurgerIsOpen(false);
                        }}>
                        {category.title}
                      </Link>
                    </DropdownHeaderCategory>
                  ))}
                </BurgerMenu>
              </BurgerWrapper>
            )}
            <DesktopHeaderSearch
              onClick={() => {
                setShowSearchForm(true);
                searchInputRef.current!.focus();
                sendClientParamsNATS(AnalyticEventNames.MainPageClickSearch, {
                  eventName: AnalyticEventNames.MainPageClickSearch,
                  eventCategory: AnalyticEventCategories.MainPage,
                });
              }}
              showSearchForm={showSearchForm}>
              <Image
                src={'/assets/icons/searchIcon.svg'}
                layout="intrinsic"
                width={16}
                height={16}
                alt="Иконка поиска"
                title="Поиск"
              />
            </DesktopHeaderSearch>
            <SearchFormContainer
              showSearch={showSearchForm}
              searchPage={router.pathname === '/search'}>
              <GlobalContainer>
                <Form>
                  <SearchBySiteForm
                    entities={entities}
                    openSearchForm={showSearchForm}
                    setShowPastReqList={setShowPastReqList}
                    width={92}
                    searchInputRef={searchInputRef}
                  />
                  <CloseBtn onClick={() => setShowSearchForm(false)} />
                </Form>
              </GlobalContainer>
            </SearchFormContainer>
          </BurgerAndSearchWrapper>
        </DesktopHeaderCategoriesWrapper>
      </GlobalContainer>
      <SearchBySitePastReqList
        queryList={searchQList}
        query={searchQ}
        open={showPastReqList}
        top={router.pathname === '/search' ? 200 : 100}
      />
    </DesktopHeaderCategories>
  );
};

export default DesktopHeaderCategoriesPanel;
